import { Box, Divider } from "@chakra-ui/react";
import React from "react";

import { defaultTheme } from "@styles";

import { NavItem, NavMenuItem } from "../../atoms";
import { NavMenuProps } from "./types";

export const NavMenu: React.FC<NavMenuProps> = ({
  menuItems,
  setActiveMenu,
  children,
}) => (
  <Box w="full" display="flex" flexDirection="column">
    <Box display="flex" mx="auto" p="1.5rem 0">
      <NavItem name="Home" url="/" />
      <Divider orientation="vertical" color={defaultTheme.colors.white} />
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="center"
        padding="0 1rem"
        listStyleType="none"
      >
        {menuItems.map((item) => (
          <NavMenuItem
            key={item.id}
            item={item}
            setActiveMenu={setActiveMenu}
            handleMouseEnter={() => setActiveMenu(item)}
          />
        ))}
      </Box>
    </Box>
    {children}
  </Box>
);
