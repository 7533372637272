import { Box } from "@chakra-ui/react";
import Image from "next/image";
import React from "react";

import { NavMenuItem } from "@components/atoms";
import { H3 } from "@components/atoms/Text/H3";
import { MenuItem } from "@graphql/gqlTypes/MenuItem";

import NavAccessoriesDesktop from "images/nav/nav-spotlight-accessories-desktop.jpg";
import NavAccessories from "images/nav/nav-spotlight-accessories-mobile.jpg";
import NavDefaultDesktop from "images/nav/nav-spotlight-alemais-desktop.jpg";
import NavDefault from "images/nav/nav-spotlight-alemais-mobile.jpg";
import NavClothingDesktop from "images/nav/nav-spotlight-clothing-desktop.jpg";
import NavClothing from "images/nav/nav-spotlight-clothing-mobile.jpg";
import NavDressesDesktop from "images/nav/nav-spotlight-dresses-desktop.jpg";
import NavDresses from "images/nav/nav-spotlight-dresses-mobile.jpg";
import NavLocationDesktop from "images/nav/nav-spotlight-location-desktop.jpg";
import NavLocation from "images/nav/nav-spotlight-location-mobile.jpg";
import NavOccasionsDesktop from "images/nav/nav-spotlight-occasions-desktop.jpg";
import NavOccasions from "images/nav/nav-spotlight-occasions-mobile.jpg";
import NavSizeDesktop from "images/nav/nav-spotlight-size-desktop.jpg";
import NavSize from "images/nav/nav-spotlight-size-mobile.jpg";

const mappedCategoryImages = {
  Dresses: {
    desktop: NavDressesDesktop,
    mobile: NavDresses,
  },
  Accessories: {
    desktop: NavAccessoriesDesktop,
    mobile: NavAccessories,
  },
  Clothing: {
    desktop: NavClothingDesktop,
    mobile: NavClothing,
  },
  Occasions: {
    desktop: NavOccasionsDesktop,
    mobile: NavOccasions,
  },
  Size: {
    desktop: NavSizeDesktop,
    mobile: NavSize,
  },
  Location: {
    desktop: NavLocationDesktop,
    mobile: NavLocation,
  },
  default: {
    desktop: NavDefaultDesktop,
    mobile: NavDefault,
  },
};

interface CategoryImageProps {
  subMenu: MenuItem;
  setActiveMenu?: () => {};
  isDesktop?: boolean;
  name?: string;
}

const CategoryImage: React.FC<CategoryImageProps> = ({
  subMenu,
  setActiveMenu,
  isDesktop,
  name,
}) => {
  const categoryImageKey =
    name && mappedCategoryImages[name] ? name : "default";
  const categoryImage =
    mappedCategoryImages[categoryImageKey]?.[isDesktop ? "desktop" : "mobile"];
  return (
    <Box>
      <Box position="relative" h="18rem">
        <Image src={categoryImage} layout="fill" objectFit="cover" />
      </Box>
      <H3 fontWeight="500">{subMenu.name ? `Rent ${subMenu?.name}` : ""}</H3>
      <NavMenuItem
        setActiveMenu={setActiveMenu}
        item={{
          name: subMenu.name
            ? `Browse ${
                subMenu?.name?.toLowerCase()?.includes("all") ? "" : "all"
              } ${subMenu?.name?.toLowerCase()}`
            : "",
          legacyUrl: subMenu?.legacyUrl,
        }}
        p="0"
        textTransform="none"
        color="tertiary"
      />
    </Box>
  );
};

export default CategoryImage;
