import { Flex, Grid, GridItem } from "@chakra-ui/react";
import React from "react";

import { NavMenuItem } from "@components/atoms";
import { H2 } from "@components/atoms/Text/H2";
import CategoryImage from "@components/molecules/MainMenuExpandableSection/CategoryImage";
import CategoryLink from "@components/molecules/MainMenuExpandableSection/CategoryLink";

import { MainMenuExpandableSectionProps } from "./types";

export const MainMenuExpandableSection: React.FC<
  MainMenuExpandableSectionProps
> = ({ activeMenu, setActiveMenu }) => {
  const subMenuItems = activeMenu ? activeMenu.children : [];
  const name = activeMenu?.name;

  if (!subMenuItems?.length) {
    return null;
  }

  const categoriesWithChildren = subMenuItems.filter(
    (subMenu) => subMenu?.children?.length > 0
  );
  const categoriesWithoutChildren = subMenuItems.filter(
    (subMenu) => !subMenu?.children || subMenu?.children?.length === 0
  );
  return (
    <nav>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}>
        <GridItem h="10" colSpan={1}>
          <Flex h="full" direction="column">
            <H2 fontWeight="500">Rent {name}</H2>
            <NavMenuItem
              item={{
                name: `Browse all ${name?.toLowerCase()}`,
                legacyUrl: activeMenu?.legacyUrl,
              }}
              setActiveMenu={setActiveMenu}
              textTransform="none"
              color="tertiary"
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={3}>
          <Grid templateColumns="repeat(4, 1fr)" gap={6}>
            {categoriesWithChildren.map((subMenu) => (
              <GridItem w="100%" pb="1rem">
                <CategoryLink subMenu={subMenu} setActiveMenu={setActiveMenu} />
              </GridItem>
            ))}
            <GridItem w="100%" pb="1rem" colSpan={2}>
              {categoriesWithoutChildren.map((subMenu) => (
                <CategoryLink subMenu={subMenu} setActiveMenu={setActiveMenu} />
              ))}
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex h="full" direction="column">
            <CategoryImage
              name={name}
              subMenu={subMenuItems[Math.min(3, subMenuItems.length - 1)]}
              setActiveMenu={setActiveMenu}
              isDesktop
            />
          </Flex>
        </GridItem>
      </Grid>
    </nav>
  );
};
