import { useStyleConfig } from "@chakra-ui/react";
import React from "react";

import { NavMenuItem } from "@components/atoms";
import { MenuItem } from "@graphql/gqlTypes/MenuItem";

interface CategoryLinkProps {
  subMenu: MenuItem;
}

const CategoryLink: React.FC<CategoryLinkProps> = ({
  subMenu,
  setActiveMenu,
}) => {
  const stylesH4 = useStyleConfig("h4");

  return (
    <>
      <NavMenuItem
        item={{
          name: subMenu?.name,
          legacyUrl: subMenu?.legacyUrl,
        }}
        fontWeight="500"
        textTransform="uppercase"
        mb="1rem"
        padding="0"
        sx={stylesH4}
        setActiveMenu={setActiveMenu}
      />
      {subMenu?.children?.map((item) => (
        <NavMenuItem
          item={{
            name: item?.name,
            legacyUrl: item?.legacyUrl,
          }}
          padding="0"
          my="0.4rem"
          textTransform="none"
          setActiveMenu={setActiveMenu}
        />
      ))}
    </>
  );
};

export default CategoryLink;
